
import { defineComponent, computed, ComputedRef,onBeforeMount } from "vue";
import { useStore } from "vuex";
import {Actions} from "@/store/enums/StoreEnums"
import { ProfileDetails } from "@/store/modules/AuthModule";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import { Integration } from "@/dtos/Integration";
import KTUpgradePlanModal from "@/components/modals/general/UpgradePlanModal.vue";


export default defineComponent({
  name: "kt-account",
  components: {
    Dropdown3,
    KTUpgradePlanModal
  },
  setup() {
    const store = useStore();
    const profile: ComputedRef<ProfileDetails> = computed(() => store.getters.getProfileDetails);
    const integrations: ComputedRef<Integration[]> = computed(() => store.getters.getIntegrations);

    async function fetchProfileDetails() {
      await store.dispatch(Actions.FETCH_PROFILEDETAILS);
    }
    onBeforeMount(async () => {
       fetchProfileDetails();
    
    });
    return { profile, fetchProfileDetails,integrations };
  },
});
